import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.hasUnsavedChanges = false
    this.sweetOptions = {
      title: 'Are you sure?',
      text: 'Are you sure you want to leave the screen without saving your changes?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Okay',
      cancelButtonText: 'Cancel',
      customClass: 'sweet-farmplan'
    }

    // Add change event to selects in the Bootgrid table
    $('#companies_index_table').on('loaded.rs.jquery.bootgrid', () => {
      this.element.querySelectorAll('[data-level-target="select"]').forEach((select) => {
        select.addEventListener('change', () => { this.hasUnsavedChanges = true })
      })
    })

    // Handle Turbo Links
    document.addEventListener('turbo:before-visit', this.handleLinks.bind(this))

    // Handle links with data-turbo="false"
    document.querySelectorAll('a[data-turbo="false"]').forEach(link => {
      link.addEventListener('click', this.handleLinks.bind(this))
    })
  }

  handleLinks(event) {
    if (this.hasUnsavedChanges) {
      event.preventDefault()
      sweetAlert(this.sweetOptions,
        (isConfirmed) => {
          if (isConfirmed) window.location.href = event.detail.url ? event.detail.url : event.target.closest('[href]').href
        })
    }
  }

  save() {
    this.hasUnsavedChanges = false
  }
}
